import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  ContainerCollumContent,
  MessagesBox,
  SendMessages,
  AttachFileIconStyled,
  SendStyled,
  NoteStyled,
  NoMessage,
  NoMessageP,
  MessagesContainer,
  MicrophoneStyled,
  AudioPlayerStyled,
  StyledTrash,
  EmojiStyled,
  GalleryFile,
  ContainerNotSelectedContact,
  Title,
  ContainerPreview,
  ContainerRec,
  RecTime,
  ContainerActions,
  LoadingContainer,
  NoMessageH1,
} from './styles'
import * as Popover from '@radix-ui/react-popover'
import ContactSuperior from './components/ContactSuperior'
import Message from './components/Message'
import Modal from './components/Modal'
import Input from '../../../../shared/components/Input'
import { getFileType, formatTime, getMessages } from '../../../../utils/format'
import { jwtDecode } from 'jwt-decode'
import EmojiModal from './components/Emoji'
import AudioLibrary from './components/AudioLibrary'
import LoadingSpinner from '../../../../shared/components/LoadingSpinner'
import LocalStorage from '../../../../utils/LocalStorage'

const Chat = ({
  socket,
  selectedContact,
  lateralContacts,
  setLateralContacts,
  currentMessages,
  messagesEndRef,
  navigation,
  setNavigation,
  LIMIT_MESSAGES,
  setMessages,
}) => {
  const [newMessage, setNewMessage] = useState('')
  const [isLoadingMessages, setIsLoadingMessages] = useState(true)
  const [isNote, setIsNote] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEmojiOpen, setIsEmojiOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [title, setTitle] = useState('')
  const [isRecording, setIsRecording] = useState(false)
  const [modalAudioLibary, setModalAudioLibary] = useState(false)
  const [mediaRecorder, setMediaRecorder] = useState(null)
  const [audioBlob, setAudioBlob] = useState(null)
  const [senderUserID, setSenderUserID] = useState(null)
  const [recordingTime, setRecordingTime] = useState(0)
  const recordingInterval = useRef(null)
  const scrollPosition = useRef(null)

  const manipulateMessages = useCallback((messages) => {
    if (messages.length === 0) return []

    let currentDay = null
    const messagesWithFlags = []

    messages.forEach((message) => {
      const date = new Date(message.sendDateMessage)
      const day = date.getDate()

      if (day !== currentDay) {
        currentDay = day
        messagesWithFlags.push({
          flag: `Mensagens do dia ${date.toLocaleDateString()}`,
          isFlag: true,
          date,
        })
      }

      messagesWithFlags.push(message)
    })
    return messagesWithFlags
  }, [])

  useEffect(() => {
    const draft = getDraft(selectedContact)
    if (draft) {
      setNewMessage(draft)
    } else {
      setNewMessage('')
    }
    if (currentMessages) {
      setIsLoadingMessages(false)
    }
  }, [currentMessages, selectedContact])

  useEffect(() => {
    if (audioBlob) {
      const audio = new File([audioBlob], 'audioMessage.webm', {
        type: 'audio/webm',
      })
      const reader = new FileReader()

      reader.onloadend = () => {
        const base64Audio = reader.result
        setFile(base64Audio)
        setTitle('Mensagem de Áudio')
      }

      reader.readAsDataURL(audio)
    }
  }, [audioBlob])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      const decoded = jwtDecode(token)
      setSenderUserID(() => decoded.id)
    }
  }, [])

  const startRecording = () => {
    setFile(null)
    setTitle('')
    setRecordingTime(0)
    try {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        const mediaRecorder = new MediaRecorder(stream)
        setMediaRecorder(mediaRecorder)
        mediaRecorder.start()
        setIsRecording(true)

        recordingInterval.current = setInterval(() => {
          setRecordingTime((prevTime) => prevTime + 1)
        }, 1000)

        mediaRecorder.ondataavailable = (e) => {
          setAudioBlob(e.data)
        }
      })
    } catch (err) {
      console.log('Error recording audio', err)
    }
  }

  const stopRecording = () => {
    mediaRecorder.stop()
    setIsRecording(false)
    clearInterval(recordingInterval.current)
  }

  const handleSendMessage = () => {
    if ((newMessage.trim() || file) && selectedContact) {
      const data = {
        typeMessage: file ? getFileType(file) : 'chat',
        toContactMessage: selectedContact,
        contentMessage: file ? title : newMessage,
        isNoteMessage: isNote,
        idReplyMessage: null,
        fileContentMessage: file,
        senderUserId: senderUserID,
      }
      socket.emit('sendMessage', data, (err) => {
        if (!err) {
          const lastMessageText = file ? title : newMessage
          setLateralContacts((prev) =>
            prev.map((contact) => {
              if (contact.id === selectedContact) {
                return {
                  ...contact,
                  lastMessage: lastMessageText,
                  isViewdMessage: true,
                }
              }
              return contact
            }),
          )
        }
      })
      setNewMessage('')
      setIsNote(false)
      setFile(null)
      setTitle('')
      LocalStorage.removeValue(`draft-${selectedContact}`)
    }
    onCloseModal()
  }

  const onCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleFileDrop = (e) => {
    e.preventDefault()

    const fileDropped = e.dataTransfer.files[0]
    if (fileDropped) {
      const reader = new FileReader()
      reader.onload = () => {
        setFile(reader.result)
      }
      reader.readAsDataURL(fileDropped)
    }
  }

  const handleFileSelect = (file) => {
    const reader = new FileReader()
    reader.onload = () => {
      setFile(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage()
    }
  }

  const onEmojiModalClose = () => {
    setIsEmojiOpen(false)
  }

  const handleDraft = (contactId, message) => {
    LocalStorage.setValue(`draft-${contactId}`, message)
  }

  const getDraft = (contactId) => {
    return LocalStorage.getValue(`draft-${contactId}`)
  }

  const handleScrollMessages = () => {
    const messagesBox = document.getElementById('messagesBox')

    if (
      messagesBox.scrollTop === 0 &&
      currentMessages.length >= LIMIT_MESSAGES
    ) {
      scrollPosition.current = messagesBox.scrollHeight - messagesBox.scrollTop

      socket.emit(
        'getMessages',
        { idContact: selectedContact },
        { limit: LIMIT_MESSAGES, offset: currentMessages.length },
        (err, response) => {
          if (!err) {
            const msg = getMessages(response)

            setMessages((prev) => ({
              ...prev,
              [selectedContact]: [...msg, ...prev[selectedContact]],
            }))

            setTimeout(() => {
              messagesBox.scrollTop =
                messagesBox.scrollHeight - scrollPosition.current
            }, 0)
          }
        },
      )
    }
  }

  if (!selectedContact) {
    return (
      <ContainerNotSelectedContact $navigation={navigation}>
        <Title>Bem-vindo ao JITZap</Title>
      </ContainerNotSelectedContact>
    )
  }

  return (
    <ContainerCollumContent $navigation={navigation}>
      {selectedContact && (
        <ContactSuperior
          setNavigation={setNavigation}
          socket={socket}
          lateralContacts={lateralContacts}
          setLateralContacts={setLateralContacts}
          dataContact={
            lateralContacts.find((contact) => contact.id === selectedContact) ||
            {}
          }
        />
      )}
      {isLoadingMessages ? (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ) : (
        <MessagesBox>
          {currentMessages.length >= 1 ? (
            <MessagesContainer
              id="messagesBox"
              onScroll={() => handleScrollMessages()}
            >
              {manipulateMessages(currentMessages).map((message, index) => (
                <Message
                  key={index}
                  message={message}
                  socket={socket}
                  dataContact={
                    lateralContacts.find(
                      (contact) => contact.id === selectedContact,
                    ) || {}
                  }
                />
              ))}
              <div ref={messagesEndRef} />
            </MessagesContainer>
          ) : currentMessages.length === 0 ? (
            <NoMessage>
              <NoMessageH1>Nenhuma mensagem</NoMessageH1>
              <NoMessageP>
                Mande uma mensagem para iniciar a conversa
              </NoMessageP>
            </NoMessage>
          ) : null}
        </MessagesBox>
      )}
      <SendMessages>
        {getFileType(file) === 'ptt' || getFileType(file) === 'audio' ? (
          <ContainerPreview>
            <AudioPlayerStyled controls src={file} />
            <ContainerActions>
              <StyledTrash
                onClick={() => {
                  setFile(null)
                  setTitle('')
                }}
              />
              <SendStyled onClick={() => handleSendMessage()} />
            </ContainerActions>
          </ContainerPreview>
        ) : (
          <>
            <ContainerActions>
              <Popover.Root>
                <Popover.Trigger asChild>
                  <EmojiStyled
                    onClick={() => {
                      setIsEmojiOpen(true)
                    }}
                  />
                </Popover.Trigger>
                <Popover.Content>
                  <EmojiModal
                    isOpen={isEmojiOpen}
                    onClose={onEmojiModalClose}
                    setNewMessage={setNewMessage}
                  />
                </Popover.Content>
              </Popover.Root>
              <Popover.Root>
                <Popover.Trigger asChild>
                  <GalleryFile
                    onClick={() => {
                      setModalAudioLibary(true)
                    }}
                  />
                </Popover.Trigger>
                <Popover.Content>
                  <AudioLibrary
                    isOpen={modalAudioLibary}
                    socket={socket}
                    sendMessage={handleSendMessage}
                    setFile={setFile}
                  />
                </Popover.Content>
              </Popover.Root>
            </ContainerActions>
            <Input
              type="text"
              placeholder="Digite sua mensagem"
              value={newMessage}
              onChange={(e) => {
                setNewMessage(e.target.value)
                handleDraft(selectedContact, e.target.value)
              }}
              onKeyDown={handleKeyDown}
            />
            <ContainerActions>
              <AttachFileIconStyled onClick={() => setIsModalOpen(true)} />
              <NoteStyled
                onClick={() => {
                  setIsNote(!isNote)
                }}
                style={{ cursor: 'pointer' }}
                $isNote={isNote}
              />
              <ContainerRec>
                <MicrophoneStyled
                  onClick={() => {
                    if (isRecording) {
                      stopRecording()
                    } else {
                      startRecording()
                    }
                  }}
                  $isRecording={isRecording}
                />
                <RecTime $isRecording={isRecording}>
                  {formatTime(recordingTime)}
                </RecTime>
              </ContainerRec>
              <SendStyled
                onClick={handleSendMessage}
                style={{ cursor: 'pointer' }}
              />
            </ContainerActions>
          </>
        )}
      </SendMessages>

      <Modal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onDrop={handleFileDrop}
        file={file}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        handleFileSelect={handleFileSelect}
        title={title}
        setTitle={setTitle}
        handleSendMessage={handleSendMessage}
      />
    </ContainerCollumContent>
  )
}

export default Chat
