import { useEffect, useState } from 'react'
import imageContato from '../../../../../../assets/images/imageContato'
import {
  extractImage,
  formatLastMessageTimeStamp,
  getTextColor,
  getFirstNChars,
} from '../../../../../../utils/format'
import {
  BlocoContact,
  DivRow,
  DivColumn,
  ContactImage,
  CountMessages,
  LastIcons,
  ImageIconWrapper,
  Div,
  ContainerTags,
  DivTag,
  TagAbbreviation,
  TextAbbreviation,
  SubTitle,
} from './styles'
import { useImage } from '../../../../../../hook/image'
import {
  ChatText,
  FileAudio,
  ImageSquare,
  FileText,
  VideoCamera,
} from 'phosphor-react'

const ContactLateral = ({
  contact,
  selectedContact,
  lastMessage,
  typeMessage,
  onContactClick,
}) => {
  const [unreadMessages, setUnreadMessages] = useState(
    contact.unreadMessageCount,
  )
  const { contactImage } = useImage()

  const playBeep = () => {
    const beep = new Audio('/notification-sound.mp3')
    beep
      .play()
      .then(() =>
        // eslint-disable-next-line
        console.log('audio ok'))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    const notificationTimeout = setTimeout(() => {
      if (
        unreadMessages < contact.unreadMessageCount &&
        (selectedContact === null || selectedContact !== contact.id)
      ) {
        setUnreadMessages(contact.unreadMessageCount)
        Notification.requestPermission()
          .then((permission) => {
            if (permission === 'granted') {
              navigator.serviceWorker.ready.then((registration) => {
                if (document.visibilityState === 'hidden') {
                  registration.showNotification('Nova mensagem', {
                    body: 'nova mensagem',
                    icon: '/android/android-launchericon-192-192.png',
                    badge: '/android/android-launchericon-72-72.png',
                  })
                } else {
                  playBeep()
                }
              })
            }
          })
          .catch((error) => console.log('Erro ao solicitar permissão: ', error))
      }
    }, 300)

    return () => clearTimeout(notificationTimeout)
  }, [contact.unreadMessageCount, selectedContact, contact.id, unreadMessages])

  useEffect(() => {
    if (selectedContact === contact.id) {
      setUnreadMessages(0)
    }
  }, [selectedContact, contact.id])

  const renderTags = () => {
    if (!contact.tags) {
      return null
    }
    return (
      <DivTag>
        {contact.tags.map((tag) => (
          <TagAbbreviation key={tag.id_tag} $color={tag.color_hex_tag}>
            <TextAbbreviation $color={() => getTextColor(tag.color_hex_tag)}>
              {tag.abbreviation_tag}
            </TextAbbreviation>
          </TagAbbreviation>
        ))}
      </DivTag>
    )
  }

  const messageIcons = {
    chat: <ChatText style={{ width: '20px' }} />,
    audio: <FileAudio style={{ width: '20px' }} />,
    image: <ImageSquare style={{ width: '20px' }} />,
    document: <FileText style={{ width: '20px' }} />,
    video: <VideoCamera style={{ width: '20px' }} />,
    ptt: <FileAudio style={{ width: '20px' }} />,
  }

  if (contact) {
    return (
      <BlocoContact
        onClick={() => onContactClick(contact.id)}
        $isSelected={selectedContact === contact.id ? 'true' : 'false'}
      >
        <DivRow>
          {extractImage(contactImage[contact.id]) !== 'null' ? (
            <ContactImage
              src={contactImage[contact.id]}
              alt="Imagem do contato"
            />
          ) : (
            <ContactImage src={imageContato} alt="Imagem do contato" />
          )}
          <DivColumn>
            <p>{contact.name}</p>
            <Div
              $isSelected={selectedContact === contact.id ? 'true' : 'false'}
            >
              {lastMessage === '' && typeMessage === '' ? (
                <ImageIconWrapper>
                  <span>Nova Conversa</span>
                </ImageIconWrapper>
              ) : lastMessage === '' ? (
                <>
                  {messageIcons[typeMessage]}
                  <SubTitle>
                    {typeMessage === 'chat' ? 'Nova mensagem' : 'Novo arquivo'}
                  </SubTitle>
                </>
              ) : (
                <>
                  {messageIcons[typeMessage]}
                  <SubTitle>
                    {lastMessage.length < 19
                      ? lastMessage
                      : `${getFirstNChars(lastMessage, contact.name.length)}...`}
                  </SubTitle>
                </>
              )}
            </Div>
          </DivColumn>
          <ContainerTags>{renderTags()}</ContainerTags>
          <LastIcons>
            <p>{formatLastMessageTimeStamp(contact.lastMessageTimestamp)}</p>
            {unreadMessages &&
            !contact.isViewedMessage &&
            contact.isViewedMessage !== null ? (
              <CountMessages></CountMessages>
            ) : null}
          </LastIcons>
        </DivRow>
      </BlocoContact>
    )
  }
}

export default ContactLateral
