import {
  Container,
  ContactContainer,
  ErrorMessage,
  LoadingContainer,
} from './styles'
import ContactLateral from './components/ContactLateral'
import SearchMenu from './components/SearchMenu'
import imageContato from '../../../../assets/images/imageContato'
import { format, formatMessage, getMessages } from '../../../../utils/format'
import { useEffect, useState } from 'react'
import { useImage } from '../../../../hook/image'
import LoadingSpinner from '../../../../shared/components/LoadingSpinner'
import FileService from '../../../../shared/services/File/FileService'
const MenuLateral = ({
  socket,
  setMessages,
  selectedContact,
  setSelectedContact,
  lateralContacts,
  setLateralContacts,
  navigation,
  setNavigation,
  LIMIT_MESSAGES,
  setContactFlag,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [selectedTags, setSelectedTags] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { setContactImage } = useImage()

  const MAX_LAST_MESSAGE_LENGTH = 25
  const LIMIT_CONTACTS = 20

  const getBase64Media = async (fileName, fileType) => {
    try {
      const response = await FileService.getSrc(fileName, fileType)
      return response.data.base64
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 500)

    return () => {
      clearTimeout(handler)
    }
  }, [searchTerm])

  useEffect(() => {
    handleLateralContacts()
  }, [debouncedSearchTerm])

  const handleLateralContacts = () => {
    socket.emit(
      'getLateralContacts',
      {
        limit: LIMIT_CONTACTS,
        offset: 0,
        tags: selectedTags.map((tag) => tag.id_tag),
        name: searchTerm,
      },
      async (err, response) => {
        if (!err) {
          const formattedData = await format(response, getBase64Media)
          formattedData.forEach((element) => {
            setContactImage(element.id, element.image)
          })
          setLateralContacts(formattedData)

          setMessages((prev) => {
            const updatedMessages = { ...prev }

            formattedData.forEach((element) => {
              if (!updatedMessages[element.id]) {
                updatedMessages[element.id] = []
              }
            })

            return updatedMessages
          })
          setIsLoading(false)
        }
      },
    )
  }

  useEffect(() => {
    handleLateralContacts()
  }, [selectedTags])

  useEffect(() => {
    handleLateralContacts()
  }, [searchTerm])

  const handleContactClick = (contactId) => {
    setSelectedContact(contactId)
    socket.emit(
      'getMessages',
      { idContact: contactId },
      { limit: LIMIT_MESSAGES, offset: 0 },
      (err, response) => {
        if (!err) {
          const msg = getMessages(response)

          const lastMessage = msg.length > 0 ? msg[msg.length - 1].message : ''
          const typeMessage =
            msg.length > 0 ? msg[msg.length - 1].typeMessage : ''

          setLateralContacts((prev) =>
            prev.map((contact) =>
              contact.id === contactId
                ? {
                    ...contact,
                    isViewedMessage: true,
                    lastMessage,
                    unreadMessageCount: 0,
                    typeMessage,
                  }
                : contact,
            ),
          )

          setMessages((prev) => ({
            ...prev,
            [contactId]: msg,
          }))
        }
      },
    )
    setContactFlag(false)
  }

  const handleScrollContacts = () => {
    const contactContainer = document.getElementById('contactContainer')

    if (
      contactContainer.scrollHeight -
        contactContainer.scrollTop -
        contactContainer.clientHeight <=
      1
    ) {
      socket.emit(
        'getLateralContacts',
        {
          limit: LIMIT_CONTACTS,
          offset: lateralContacts.length,
          tags: selectedTags.map((tag) => tag.id_tag),
          name: searchTerm,
        },
        async (err, response) => {
          if (!err) {
            const formattedData = await format(response, getBase64Media)
            formattedData.forEach((element) => {
              setContactImage(element.id, element.image)
            })
            setLateralContacts((prev) => {
              // Adicionar apenas contatos que não estão no estado atual
              const newContacts = formattedData.filter(
                (newContact) =>
                  !prev.some(
                    (existingContact) => existingContact.id === newContact.id,
                  ),
              )
              return [...prev, ...newContacts]
            })
          }
        },
      )
    }
  }

  return (
    <>
      <Container $navigation={navigation}>
        <SearchMenu
          setSearchTerm={setSearchTerm}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          setSelectedContact={setSelectedContact}
        />
        <ContactContainer id="contactContainer" onScroll={handleScrollContacts}>
          {isLoading ? (
            <LoadingContainer>
              <LoadingSpinner strokeColor="white" />
            </LoadingContainer>
          ) : lateralContacts.length === 0 ? (
            <ErrorMessage>Nenhum contato encontrado!</ErrorMessage>
          ) : (
            lateralContacts.map((contact, index) => {
              if (contact.image === `data:image/png;base64, null`) {
                contact.image = imageContato
              }
              const lastMessage = contact.lastMessage || ''
              const limitedLastMessage =
                lastMessage.length > MAX_LAST_MESSAGE_LENGTH
                  ? formatMessage(lastMessage).substring(
                      0,
                      MAX_LAST_MESSAGE_LENGTH,
                    ) + '...'
                  : formatMessage(lastMessage)
              const typeMessage = contact.typeMessage || ''

              return (
                <ContactLateral
                  socket={socket}
                  key={index}
                  contact={contact}
                  typeMessage={typeMessage}
                  lastMessage={limitedLastMessage}
                  onContactClick={() => {
                    handleContactClick(contact.id)
                    setNavigation('chat')
                  }}
                  selectedContact={selectedContact}
                />
              )
            })
          )}
        </ContactContainer>
      </Container>
    </>
  )
}

export default MenuLateral
